import { Flex, Heading } from "@chakra-ui/react";
import React from "react";

export default function Projects() {
  return (
    <Flex direction="column">
      <Flex justify="space-between" p={4}>
        <Heading>Projects</Heading>
      </Flex>
    </Flex>
  );
}
