import { HStack, IconButton, Link } from "@chakra-ui/react";
import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";

export default function Socials() {
  const socials = [
    {
      icon: <FaGithub />,
      url: "https://github.com/sveinndarri",
      key: "social-github",
      ariaLabel: "See more about me on Github",
    },
    {
      icon: <FaLinkedin />,
      url: "https://www.linkedin.com/in/sveinndarri/",
      key: "social-linkedin",
      ariaLabel: "See more about me on LinkedIn",
    },
  ];

  return (
    <HStack spacing={4}>
      {socials?.map(({ icon, url, key, ariaLabel }) => (
        <Link href={url} key={key}>
          <IconButton size="lg" icon={icon} aria-label={ariaLabel} />
        </Link>
      ))}
    </HStack>
  );
}
