import { Avatar } from "@chakra-ui/react";
import React from "react";
import avatar from "../avatar.webp";
import ColorModeToggle from "./ColorModeToggle";

export default function Logo() {
  return (
    <ColorModeToggle>
      <Avatar size="2xl" name="Sveinn Darri Ingólfsson" src={avatar} />
    </ColorModeToggle>
  );
}
