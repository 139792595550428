import { Center, Link, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function Info() {
  return (
    <Center w="100%">
      <VStack>
        <Text fontSize="3xl" fontWeight="extrabold">
          Sveinn Darri Ingólfsson
        </Text>
        <Text fontWeight="light">
          Software developer at{" "}
          <Link href="https://corivo.io/" isExternal>
            Corivo
          </Link>
        </Text>
      </VStack>
    </Center>
  );
}
