import { Flex, VStack } from "@chakra-ui/react";
import React from "react";
import Info from "./Info";
import Logo from "./Logo";
import Socials from "./Socials";

export default function Home() {
  return (
    <Flex minH="100vh" justify="center" align="center">
      <VStack spacing={6}>
        <Logo />
        <Info />
        <Socials />
      </VStack>
    </Flex>
  );
}
