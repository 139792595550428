import { Box, Flex, Tooltip, useColorMode } from "@chakra-ui/react";
import React from "react";
import { FaRegKissWinkHeart } from "react-icons/fa";

export default function ColorModeToggle({ children }) {
  const { toggleColorMode } = useColorMode();

  return (
    <Tooltip
      label={
        <Flex align="center" p={2}>
          <Box mr={2}>Click me</Box>
          <Box>
            <FaRegKissWinkHeart size="24px" />
          </Box>
        </Flex>
      }
    >
      <Box cursor="pointer" onClick={toggleColorMode}>
        {children}
      </Box>
    </Tooltip>
  );
}
